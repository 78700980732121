import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/controltower/takeUpCompaniesUsers',
  api,
  {
    importLogUrl: '/api/controltower/importLog',
    masterName: 'companyId'
  }
)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.user.options = ctx.userOptions
  fieldsById.company.options = ctx.companyOptions
}

export default service
