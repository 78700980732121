<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :details="details"
      :metadata="metadata"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/takeUpCompanyService'
import takeUpCompaniesUsersService from '../api/takeUpCompaniesUsersService'
import takeUpCompanyProducerService from '../api/takeUpCompanyProducerService'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      crudService: service,
      metadata: null,
      details: {}
    }
  },
  methods: {
    async getTakeUpCompanyProducerMeta() {
      const takeUpCompanyProducerMeta =
        await takeUpCompanyProducerService.getMeta()
      helpers.processMetaDetails(
        takeUpCompanyProducerMeta,
        'takeUpCompanyProducer',
        'companyId',
        'company',
        takeUpCompanyProducerService
      )
      return takeUpCompanyProducerMeta
    },
    async getTakeUpCompaniesUsersMeta() {
      const takeUpCompaniesUsersMeta =
        await takeUpCompaniesUsersService.getMeta()
      helpers.processMetaDetails(
        takeUpCompaniesUsersMeta,
        'takeUpCompaniesUsers',
        'companyId',
        'company',
        takeUpCompaniesUsersService
      )
      return takeUpCompaniesUsersMeta
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getTakeUpCompaniesUsersMeta(),
        this.getTakeUpCompanyProducerMeta()
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'controlTower.pages')
      this.metadata = meta
    })
  }
}
</script>
