import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/controltower/takeUpCompanyProducer',
  api,
  { importLogUrl: '/api/controltower/importLog', masterName: 'companyId' }
)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.company.options = ctx.companyOptions
  fieldsById.producer.options = ctx.producerOptions
}

export default service
